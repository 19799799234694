import React, { useEffect } from 'react'

import { EntitlementSearchContainer } from 'containers/Entitlements'
import EntitlementSearch from 'components/pages/entitlements/SearchEntitlements'

import swal from '@sweetalert/with-react'

const EntitlementSearchRoute = props => {
  let container = EntitlementSearchContainer.useContainer()
  let { getEntitlements } = container
  let { history, location, match } = props
  let { search } = location

  // Handle query string param
  useEffect(() => {
    if (search) {
      let params = new URLSearchParams(search)
      if (params.has('email')) {
        getEntitlements(params.get('email'))
      }
    }
  }, [search, getEntitlements])

  const searchEntitlements = email => {
    history.push(`?email=${email}`)
  }

  const resetKey = id => {
    container.resetKey(id, {
      onSuccess: () => {
        swal('Success', 'Key reset.', 'success')
      },
      onError: err => {
        swal('Error', `Error resetting key: ${err.message}`, 'error')
      },
    })
  }

  const sendEntitlementEmail = id => {
    container.sendEntitlementEmail(id, {
      onSuccess: () => {
        swal('Success', 'Email sent.', 'success')
      },
      onError: err => {
        swal('Error', `Error sending email: ${err.message}`, 'error')
      },
    })
  }

  const editEntitlement = id => {
    history.push(`${match.url}/edit/${id}`)
  }

  const pageProps = {
    ...props,
    ...container,
    searchEntitlements,
    resetKey,
    sendEntitlementEmail,
    editEntitlement,
  }

  return <EntitlementSearch {...pageProps} />
}

export default EntitlementSearchRoute
