import fetch from 'isomorphic-fetch'
import config from 'config'
import { ApiError, BadRequestError } from 'lib/errors'
import { getAccessToken } from 'lib/utils'

const getFullUrl = (url) => (url.startsWith('http') ? url : config.apiUrl + url)

const getHeaders = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }
}

const execute = async (url, options) => {
  const optionsWithHeaders = { ...options, ...getHeaders() }
  const response = await fetch(getFullUrl(url), optionsWithHeaders)

  if (!response.ok) {
    let body = await response.json()
    body = body || {}

    if (response.status === 400) {
      throw new BadRequestError('Invalid Request', response, body.errors)
    } else {
      const errorMessage = body.message || 'Error calling API'
      throw new ApiError(errorMessage, response)
    }
  }

  return response
}

export const get = async (url) => {
  const options = {
    method: 'GET',
  }

  return await execute(url, options)
}

export const patch = async (url, body) => {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(body),
  }

  return execute(url, options)
}

export const post = async (url, body) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
  }

  return execute(url, options)
}
