import { useState, useCallback, useMemo } from 'react'
import { createContainer } from 'unstated-next'
import AuthService from '../lib/auth'

function useAuth() {
  let auth = useMemo(() => new AuthService(), [])
  let [state, setState] = useState({
    user: null,
    authenticating: false,
    error: null,
  })

  let signin = useCallback(() => {
    setState(s => ({ ...s, authenticating: true, error: null }))
    auth.signin()
  }, [auth])

  let signout = useCallback(() => {
    auth.signout()
    setState(s => ({
      ...s,
      user: null,
      authenticated: false,
      authenticating: false,
    }))
  }, [auth])

  let logout = useCallback(() => {
    auth.logout()
    setState(s => ({
      ...s,
      user: null,
      authenticated: false,
      authenticating: false,
    }))
  }, [auth])

  let handleAuthentication = async location => {
    try {
      let authResult = await auth.handleAuthentication(location)

      setState({
        ...state,
        user: { ...authResult.idTokenPayload },
        authenticated: true,
        authenticating: false,
        error: null,
      })
    } catch (err) {
      setState({
        ...state,
        user: null,
        authenticated: true,
        authenticating: false,
        error: err.error,
      })
    }
  }

  let checkSession = useCallback(async () => {
    if (auth.isAuthenticated()) {
      return true
    }

    try {
      await auth.checkSession()
      return true
    } catch (err) {
      return false
    }
  }, [auth])

  let isAuthenticated = useCallback(() => auth.isAuthenticated(), [auth])

  let pushRedirectUrl = useCallback(url => {
    localStorage.setItem('redirect_url', url)
  }, [])

  let popRedirectUrl = useCallback(() => {
    const url = localStorage.getItem('redirect_url')
    localStorage.removeItem('redirect_url')

    return url
  }, [])

  return {
    state,
    signin,
    signout,
    logout,
    handleAuthentication,
    checkSession,
    isAuthenticated,
    pushRedirectUrl,
    popRedirectUrl,
  }
}

export default createContainer(useAuth)
