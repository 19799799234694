import auth0 from 'auth0-js'
import config from '../config'

export default class AuthService {
  auth0 = new auth0.WebAuth({
    domain: config.auth0.domain,
    clientID: config.auth0.clientId,
    audience: config.auth0.audience,
    redirectUri: config.auth0.redirectUri,
    scope: 'openid profile email',
    responseType: 'token id_token',
  })

  signin = () => {
    console.log(`Initiaiting Signin...`)
    console.log(`Audience: ${config.auth0.audience}`)
    console.log(`Redirect Uri: ${config.auth0.redirectUri}`)

    this.auth0.authorize({})
  }

  signout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('id_token_payload')
  }

  logout = () => {
    this.signout()
    this.auth0.logout({
      returnTo: config.auth0.logoutUri,
    })
  }

  handleAuthentication = location => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash(location, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult)
          return resolve(authResult)
        } else if (err) {
          console.log(err)
          return reject(err)
        }
      })
    })
  }

  checkSession = () => {
    return new Promise((resolve, reject) => {
      return this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          console.log(err)
          return reject(err)
        }

        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult)
          return resolve(authResult)
        }
      })
    })
  }

  setSession = authResult => {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    )
    localStorage.setItem('access_token', authResult.accessToken)
    localStorage.setItem('id_token', authResult.idToken)
    localStorage.setItem('expires_at', expiresAt)
    localStorage.setItem(
      'id_token_payload',
      JSON.stringify(authResult.idTokenPayload)
    )
  }

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    return new Date().getTime() < expiresAt
  }

  getProfile = () => {
    const parseProfile = data => {
      if (data) {
        try {
          return JSON.parse(data)
        } catch (e) {
          return {}
        }
      }
    }

    return this.isAuthenticated()
      ? parseProfile(localStorage.getItem('profile'))
      : {}
  }
}
