import React from 'react'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

import GridContainer from 'components/atoms/GridContainer'
import GridItem from 'components/atoms/GridItem'
import Card from 'components/molecules/Card'
import CardHeader from 'components/molecules/CardHeader'
import CardFooter from 'components/molecules/CardFooter'
import CardIcon from 'components/molecules/CardIcon'
import CardBody from 'components/molecules/CardBody'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import { validate } from 'lib/formHelpers'
import { saveLicenseKeyPool } from 'lib/validationSchema'

export default ({ onUpdateLicenseKeyPool, licenseKeyPool, classes }) => {
  return (
    <Formik
      initialValues={licenseKeyPool}
      validate={validate(saveLicenseKeyPool)}
      validateOnChange={false}
      onSubmit={onUpdateLicenseKeyPool}
      render={({ values, errors, setFieldValue, isSubmitting }) => (
        <Form role="form">
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon>edit</Icon>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Edit LicenseKey Pool</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Field
                    label="Name"
                    name="name"
                    margin="normal"
                    component={TextField}
                    fullWidth
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Field
                    label="Reorder Threshold"
                    name="reorderThreshold"
                    margin="normal"
                    component={TextField}
                    fullWidth
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Field
                    label="Reorder Quantity"
                    name="reorderQuantity"
                    margin="normal"
                    component={TextField}
                    fullWidth
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="submit" disabled={isSubmitting}>
                Update
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    />
  )
}
