import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import wrapComponent from 'components/wrappers/wrapComponent'

import KeyIcon from '@material-ui/icons/VpnKey'

// Containers
import {
  EntitlementProductsContainer,
  EntitlementSearchContainer,
  EntitlementContainer,
} from 'containers/Entitlements'
import LicenseKeyContainer from 'containers/licenseKeys/LicenseKeyContainer'

// Routes
import SearchEntitlements from 'routes/entitlements/Search'
import CreateEntitlement from 'routes/entitlements/CreateEntitlementRoute'
import LicenseKeyPoolsIndexRoute from 'routes/licenseKeys/LicenseKeyPoolsIndexRoute'

const allRoutes = [
  {
    collapse: true,
    layout: '/admin',
    path: '/entitlements',
    name: 'Entitlements',
    icon: KeyIcon,
    containers: [EntitlementContainer, EntitlementProductsContainer],
    views: [
      {
        layout: '/admin',
        path: '/entitlements/search',
        name: 'Search Entitlements',
        mini: 'SE',
        component: SearchEntitlements,
        containers: [EntitlementSearchContainer],
      },
      {
        layout: '/admin',
        path: '/entitlements/create',
        name: 'Create Entitlement',
        mini: 'CE',
        component: CreateEntitlement,
      },
      {
        redirect: true,
        from: '/admin/entitlements',
        to: '/admin/entitlements/search',
      },
    ],
  },
  {
    collapse: true,
    layout: '/admin',
    path: '/licenseKeys',
    name: 'License Keys',
    icon: KeyIcon,
    containers: [LicenseKeyContainer],
    views: [
      {
        layout: '/admin',
        path: '/licenseKeys/pools',
        name: 'License Key Pools',
        mini: 'LP',
        component: LicenseKeyPoolsIndexRoute,
        containers: [],
      },
      {
        redirect: true,
        from: '/admin/licenseKeys',
        to: '/admin/licenseKeys/pools',
      },
    ],
  },
  {
    redirect: true,
    layout: '/admin',
    from: '/admin',
    to: '/admin/entitlements',
  },
]

const getComponent = (route, parentContainers) => {
  let { containers: routeContainers, component } = route

  parentContainers = parentContainers || []
  routeContainers = routeContainers || []
  const containers = [...parentContainers, ...routeContainers]

  if (containers.length > 0) {
    let wrapper = containers.reduce(
      (acc, container) =>
        acc ? wrapComponent(acc, container.Provider) : container.Provider,
      null
    )

    return wrapComponent(wrapper, component)
  } else {
    return component
  }
}

export const renderSwitch = routes => {
  return <Switch>{renderRoutes(routes)}</Switch>
}

const fullPath = route => route.layout + route.path

const renderRoutes = (routes, containers) => {
  return routes.map((prop, key) => {
    if (prop.collapse) {
      return renderRoutes(prop.views, prop.containers)
    }

    if (prop.redirect) {
      return <Redirect key={key} from={prop.from} to={prop.to} />
    }

    return (
      <Route
        path={fullPath(prop)}
        component={getComponent(prop, containers)}
        key={key}
      />
    )
  })
}

export const getCurrentRouteName = routes => {
  let activeRoute = 'Default Brand Text'

  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveRoute = getCurrentRouteName(routes[i].views)
      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute
      }
    } else {
      if (window.location.href.indexOf(fullPath(routes[i])) !== -1) {
        return routes[i].name
      }
    }
  }

  return activeRoute
}

export const getAdminRoutes = () => {
  return allRoutes.filter(r => r.layout === '/admin')
}

export default allRoutes
