import React, { useEffect } from 'react'
import AuthContainer from '../../containers/AuthContainer'

export default () => {
  let container = AuthContainer.useContainer()

  /* eslint-disable */
  useEffect(() => {
    container.signout()
    container.signin()
  }, [])
  /* eslint-enable */

  return <div />
}
