import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import AdminNavbar from 'components/organisms/AdminNavbar.jsx'
import Footer from 'components/organisms/Footer.jsx'
import Sidebar from 'components/organisms/Sidebar.jsx'

import { getAdminRoutes, renderSwitch, getCurrentRouteName } from 'routes'

import appStyle from './adminStyle.jsx'

import image from 'assets/img/sidebar-2.jpg'
import logoSz from 'assets/img/logo-sz.png'
import logoAts from 'assets/img/logo-ats.png'
import config from 'config'

var ps

const getLogo = () => (config.tenant === 'safebytes' ? logoAts : logoSz)

class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: image,
      color: 'blue',
      bgColor: 'black',
      hasImage: true,
      fixedClasses: 'dropdown',
    }
    this.resizeFunction = this.resizeFunction.bind(this)
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
      document.body.style.overflow = 'hidden'
    }
    window.addEventListener('resize', this.resizeFunction)
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy()
    }
    window.removeEventListener('resize', this.resizeFunction)
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false })
      }
    }
  }

  handleImageClick = image => {
    this.setState({ image: image })
  }

  handleColorClick = color => {
    this.setState({ color: color })
  }

  handleBgColorClick = bgColor => {
    this.setState({ bgColor: bgColor })
  }

  handleFixedClick = () => {
    if (this.state.fixedClasses === 'dropdown') {
      this.setState({ fixedClasses: 'dropdown show' })
    } else {
      this.setState({ fixedClasses: 'dropdown' })
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }

  getActiveRoute = routes => {
    let activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive })
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false })
    }
  }

  render() {
    const { classes, ...rest } = this.props
    const adminRoutes = getAdminRoutes()

    const mainPanel =
      classes.mainPanel +
      ' ' +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1,
      })
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={adminRoutes}
          logoText={config.appTitle}
          logo={getLogo()}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={getCurrentRouteName(adminRoutes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>{renderSwitch(adminRoutes)}</div>
          </div>
          <Footer fluid />
        </div>
      </div>
    )
  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(appStyle)(Admin)
