import React, { useEffect } from 'react'

import {
  EntitlementProductsContainer,
  EntitlementContainer,
} from 'containers/Entitlements'

import swal from '@sweetalert/with-react'
import CreateEntitlement from 'components/pages/entitlements/CreateEntitlement'
import { BadRequestError } from 'lib/errors'

const CreateEntitlementRoute = props => {
  let productsContainer = EntitlementProductsContainer.useContainer()
  let { fetchProducts } = productsContainer
  let entitlementContainer = EntitlementContainer.useContainer()
  let { createEntitlement } = entitlementContainer
  let { history } = props

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    createEntitlement(values, {
      onSuccess: data => {
        if (data.hasPendingFulfillments) {
          swal(
            'Pending Fulfillments',
            'Customer has a pending fulfillment for this product.',
            'info'
          )
          setSubmitting(false)
        } else {
          history.push(
            `/admin/entitlements/search?email=${values.customerEmail}`
          )
        }
      },
      onError: err => {
        if (err instanceof BadRequestError) {
          setErrors(err.errors)
        } else {
          swal('Unexpected Error', `Error occurred: ${err.message}`, 'error')
        }
        setSubmitting(false)
      },
    })
  }

  let pageProps = { ...props, ...productsContainer, onSubmit }

  return <CreateEntitlement {...pageProps} />
}

export default CreateEntitlementRoute
