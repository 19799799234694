import { useContainer } from 'unstated-next'
import { useState, useEffect, useMemo } from 'react'
import { get, post, patch } from 'lib/api'

import { AuthError } from 'lib/errors'
import AuthContainer from 'containers/AuthContainer'

let initialState = {
  loading: false,
  failed: false,
  error: null,
  succeeded: false,
  data: null,
}

let useApi = () => {
  let status = useMemo(
    () => ({
      idle: initialState,
      fetching: {
        ...initialState,
        loading: true,
      },
      failed: error => ({
        ...initialState,
        failed: true,
        error,
      }),
      succeeded: data => ({
        ...initialState,
        succeeded: true,
        data,
      }),
    }),
    []
  )

  let apiProxy = useMemo(
    () => ({
      get: (url, options = {}) => {
        setRequest(status.fetching)
        setApiCall({ execute: get, url, values: {}, options })
      },
      post: (url, values, options = {}) => {
        values = values || {}
        setRequest(status.fetching)
        setApiCall({ execute: post, url, values, options })
      },
      patch: (url, values, options = {}) => {
        values = values || {}
        setRequest(status.fetching)
        setApiCall({ execute: patch, url, values, options })
      },
    }),
    [status]
  )

  let authContainer = useContainer(AuthContainer)
  let { isAuthenticated, checkSession, signout } = authContainer

  let [request, setRequest] = useState(status.idle)
  let [apiCall, setApiCall] = useState(null)

  useEffect(() => {
    ;(async () => {
      if (!apiCall) return
      let { execute, url, values, options } = apiCall
      let { onSuccess, onError } = options

      try {
        // Ensure token
        await checkSession()

        let response = await execute(url, values)

        let data = {}
        // Check for no-content response
        if (response.status !== 204) {
          data = await response.json()
        }

        if (onSuccess) {
          onSuccess(data)
        }

        setRequest(status.succeeded(data))
      } catch (err) {
        console.log(err)

        if (err instanceof AuthError) {
          signout()
        }

        if (onError) {
          onError(err)
        }

        setRequest(status.failed(err))
      }
    })()
  }, [apiCall, status, isAuthenticated, checkSession, signout])

  return [request, apiProxy]
}

export default useApi
