import React from 'react'
import { Formik, Form, Field } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

import GridContainer from 'components/atoms/GridContainer'
import GridItem from 'components/atoms/GridItem'
import Card from 'components/molecules/Card'
import CardHeader from 'components/molecules/CardHeader'
import CardFooter from 'components/molecules/CardFooter'
import CardIcon from 'components/molecules/CardIcon'
import CardBody from 'components/molecules/CardBody'

import AddIcon from '@material-ui/icons/Add'

import { validate } from 'lib/formHelpers'
import { createEntitlementSchema } from '../../../lib/validationSchema'

import { withStyles } from '@material-ui/core/styles'
import styles from './entitlementsStyle'

let initialValues = {
  productId: 0,
  quantity: 1,
  customerName: '',
  customerEmail: '',
}

const CreateEntitlement = ({ classes, fetchProductsRequest, onSubmit }) => {
  let { data: products } = fetchProductsRequest
  products = products || []

  const getQuantityLabel = id => {
    var product = products.find(p => p.id === id)
    return product && product.isSeatBased ? 'Seats' : 'Quantity'
  }

  return (
    <GridContainer>
      <GridItem sm={12}>
        <Formik
          initialValues={initialValues}
          validate={validate(createEntitlementSchema)}
          validateOnChange={false}
          onSubmit={onSubmit}
          render={({ values, errors, setFieldValue, isSubmitting }) => (
            <Form role="form">
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <AddIcon />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        Create Entitlement
                      </h4>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="productId">Product</InputLabel>
                            <Field
                              inputProps={{ id: 'productId' }}
                              name="productId"
                              component={Select}
                              fullWidth
                            >
                              {products.map(p => (
                                <MenuItem key={p.id} value={p.id}>
                                  {p.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <Field
                            label={getQuantityLabel(values.productId)}
                            name="quantity"
                            margin="normal"
                            component={TextField}
                            fullWidth
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <Field
                            label="Customer Name"
                            name="customerName"
                            margin="normal"
                            component={TextField}
                            fullWidth
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <Field
                            label="Customer Email"
                            name="customerEmail"
                            margin="normal"
                            component={TextField}
                            fullWidth
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <CardFooter>
                      <Button type="submit" disabled={isSubmitting}>
                        Create
                      </Button>
                    </CardFooter>
                  </Card>
                </GridItem>
              </GridContainer>
            </Form>
          )}
        />
      </GridItem>
    </GridContainer>
  )
}

export default withStyles(styles)(CreateEntitlement)
