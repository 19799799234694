let hostname = window && window.location && window.location.hostname
const isQA = hostname.startsWith('qa-admin')
const isProduction = hostname.startsWith('admin')
const tenant = process.env.REACT_APP_TENANT

const getEnvKey = () => {
  if (isQA) {
    return 'qa'
  } else if (isProduction) {
    return 'prod'
  } else {
    return 'other'
  }
}

let envKey = getEnvKey()

let tenantConfig = {
  idps: {
    title: 'IDPS Admin',
    auth0: {
      domain: 'idps.auth0.com',
      clientId: 'KoSbN2mRw6KaL9JAbhwxlrktUrKNakwS',
      audience: 'https://api.idsafe.io',
    },
    urls: {
      api: {
        qa: 'https://qa-api.idsafe.io',
        prod: 'https://api.idsafe.io',
        other: process.env.REACT_APP_API_URL || 'http://localhost:5000',
      },
      redirect: {
        qa: 'https://qa-admin.idsafe.io/callback',
        prod: 'https://admin.idsafe.io/callback',
        other:
          process.env.REACT_APP_AUTH0_REDIRECT_URI ||
          'http://localhost:3000/callback',
      },
      logout: {
        qa: 'https://qa-admin.idsafe.io',
        prod: 'https://admin.idsafe.io',
        other:
          process.env.REACT_APP_AUTH0_LOGOUT_URI || 'http://localhost:3000',
      },
    },
  },
  stopzilla: {
    title: 'STOPzilla Admin',
    auth0: {
      domain: 'stopzilla.auth0.com',
      clientId: 'sYFQStdptlFQegRXk44OnQ3fwwsqoXvw',
      audience: 'https://api.stopzilla.com',
      authorizationUrl:
        'https://stopzilla.us8.webtask.io/adf6e2f2b84784b57522e3b19dfc9201',
    },
    urls: {
      api: {
        qa: 'https://qa-api.stopzilla.com',
        prod: 'https://api.stopzilla.com',
        other: process.env.REACT_APP_API_URL || 'http://localhost:5000',
      },
      redirect: {
        qa: 'https://qa-admin.stopzilla.com/callback',
        prod: 'https://admin.stopzilla.com/callback',
        other:
          process.env.REACT_APP_AUTH0_REDIRECT_URI ||
          'http://localhost:3000/callback',
      },
      logout: {
        qa: 'https://qa-admin.stopzilla.com',
        prod: 'https://admin.stopzilla.com',
        other:
          process.env.REACT_APP_AUTH0_LOGOUT_URI || 'http://localhost:3000',
      },
    },
  },
  safebytes: {
    title: 'SafeBytes Admin',
    auth0: {
      domain: 'safebytes.auth0.com',
      clientId: 'IyDLoLSci03fm1B7CQOl6lU7d9GZyOIL',
      audience: 'https://api.safebytes.com',
    },
    urls: {
      api: {
        qa: 'https://qa-api.safebytes.com',
        prod: 'https://api.safebytes.com',
        other: process.env.REACT_APP_API_URL || 'http://localhost:5000',
      },
      redirect: {
        qa: 'https://qa-admin.safebytes.com/callback',
        prod: 'https://admin.safebytes.com/callback',
        other:
          process.env.REACT_APP_AUTH0_REDIRECT_URI ||
          'http://localhost:3000/callback',
      },
      logout: {
        qa: 'https://qa-admin.safebytes.com',
        prod: 'https://admin.safebytes.com',
        other:
          process.env.REACT_APP_AUTH0_LOGOUT_URI || 'http://localhost:3000',
      },
    },
  },
}

const getTenantUrl = (type) => {
  return tenantConfig[tenant]['urls'][type][envKey]
}

const getHomeServiceApiUrl = () => {
  if (isProduction) {
    return 'https://home.is3.com'
  } else {
    return 'https://qa-web-home.pxegroup.net'
  }
}

export default {
  tenant,
  env: process.env.NODE_ENV || 'development',
  isDev: process.env.NODE_ENV !== 'production',
  basename: process.env.PUBLIC_PATH,
  isBrowser: typeof window !== 'undefined',
  auth0: {
    ...tenantConfig[tenant].auth0,
    redirectUri: getTenantUrl('redirect'),
    logoutUri: getTenantUrl('logout'),
  },
  appTitle: tenantConfig[tenant].title,
  apiUrl: getTenantUrl('api'),
  homeServiceApiUrl: getHomeServiceApiUrl(),
}
