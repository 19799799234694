import React from 'react'
import { Switch, Route } from 'react-router'
import SearchEntilementsRoute from './search/SearchEntitlementsRoute'
import EditEntitlementRoute from './search/EditEntitlementRoute'

export default ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/edit/:id`} component={EditEntitlementRoute} />
      <Route path={`${match.url}`} component={SearchEntilementsRoute} />
    </Switch>
  )
}
