import React, { useState } from 'react'
import { parseISO, format } from 'date-fns'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import EmailIcon from '@material-ui/icons/Email'
import EditIcon from '@material-ui/icons/Edit'
import ResetIcon from '@material-ui/icons/Refresh'

import ReactTable from 'react-table'

import GridContainer from 'components/atoms/GridContainer'
import GridItem from 'components/atoms/GridItem'

import { withStyles } from '@material-ui/core/styles'
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
})

const EntitlementSearch = props => {
  let {
    email,
    getEntitlementsRequest,
    searchEntitlements,
    sendEntitlementEmail,
    resetKey,
    editEntitlement,
  } = props
  let { loading, succeeded, error, data: entitlements } = getEntitlementsRequest
  let [searchValue, setSearchValue] = useState(email)

  const handleInput = e => {
    setSearchValue(e.target.value)
  }

  const handleSearch = e => {
    e.preventDefault()
    const trimmed = searchValue.trim()
    searchEntitlements(trimmed)

    if (searchValue !== trimmed) {
      setSearchValue(trimmed)
    }
  }

  const columns = [
    {
      Header: 'Customer Name',
      accessor: 'customerName',
    },
    {
      id: 'ProductName',
      Header: 'Product Name',
      accessor: e => e.product.name,
    },
    {
      Header: 'Activation Code',
      accessor: 'activationCode',
    },
    {
      Header: 'Starts',
      accessor: 'starts',
    },
    {
      Header: 'Ends',
      accessor: 'ends',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      sortable: false,
      filterable: false,
    },
  ]

  const getDate = date =>
    date ? format(parseISO(date), 'MMM do yyyy') : 'Never'

  const withActions = () => {
    return !succeeded
      ? []
      : entitlements.map(e => ({
          ...e,
          starts: getDate(e.starts),
          ends: getDate(e.ends),
          actions: (
            <div className="actions-right">
              {e.product.provider === 2000 && (
                <Tooltip title="Reset Key" placement="top">
                  <Button
                    onClick={() => resetKey(e.activationCode)}
                    color="default"
                    className="remove"
                    aria-label="Reset Key"
                  >
                    <ResetIcon />
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Resend Email" placement="top">
                <Button
                  onClick={() => sendEntitlementEmail(e.id)}
                  color="default"
                  className="remove"
                  aria-label="Resend Email"
                >
                  <EmailIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Edit" placement="top">
                <Button
                  onClick={() => editEntitlement(e.id)}
                  color="default"
                  className="remove"
                  aria-label="Edit Entitlement"
                >
                  <EditIcon />
                </Button>
              </Tooltip>
            </div>
          ),
        }))
  }

  return (
    <div>
      <GridContainer spacing={24}>
        <GridItem xs={12}>
          <form onSubmit={handleSearch}>
            <GridContainer spacing={24}>
              <GridItem md={2}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Email"
                  value={searchValue}
                  onChange={handleInput}
                />
              </GridItem>
              <GridItem md={10}>
                <Button color="primary" onClick={handleSearch}>
                  Search
                </Button>
              </GridItem>
            </GridContainer>
          </form>
          <div>
            {email && <h3>Search Results for {email}</h3>}
            {loading && <h3>&nbsp;</h3>}
            {error && <h3>Error: {error.message}</h3>}
            <ReactTable
              data={withActions()}
              columns={columns}
              showPaginationTop={false}
              showPaginationBottom={true}
              defaultPageSize={10}
              loading={getEntitlementsRequest.loading}
              noDataText={
                succeeded ? 'No results found' : 'Enter the email to search'
              }
            />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default withStyles(styles)(EntitlementSearch)
