import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import AuthContainer from './containers/AuthContainer'

import requiresAuth from './components/wrappers/requiresAuth'

import Login from './routes/auth/Login'
import Callback from './routes/auth/Callback'
import Admin from './components/templates/Admin'
import config from './config'
import useDocumentTitle from './lib/useDocumentTitle'

const App: React.FC = () => {
  useDocumentTitle(config.appTitle)

  return (
    <BrowserRouter>
      <AuthContainer.Provider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/callback" component={Callback} />
          <Route path="/admin" component={requiresAuth(Admin)} />
          <Redirect from="/" to="/admin" />
        </Switch>
      </AuthContainer.Provider>
    </BrowserRouter>
  )
}

export default App
