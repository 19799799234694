import { useState, useEffect, useCallback } from 'react'
import { createContainer } from 'unstated-next'
import EntitlementContainer from './EntitlementContainer'
import useApi from 'lib/useApi'

let useEntitlementSearch = initialState => {
  let entitlementContainer = EntitlementContainer.useContainer()
  let [getEntitlementsRequest, api] = useApi()
  let [email, setEmail] = useState(null)

  useEffect(() => {
    if (!email) return

    api.get(`/ent/entitlements?email=${email}`)
  }, [api, email])

  let getEntitlements = useCallback(email => {
    setEmail(email)
  }, [])

  return {
    ...entitlementContainer,

    email,
    getEntitlements,
    getEntitlementsRequest,
  }
}

export default createContainer(useEntitlementSearch)
