import { cardTitle } from 'assets/jss/site.jsx'

export default theme => ({
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center',
  },
  description: {
    color: '#999999',
  },
  updateProfileButton: {
    float: 'right',
  },
  left: {
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  updateButton: {
    float: 'right',
  },
  paper: {
    padding: theme.spacing(1, 2),
  },
  breadcrumbLink: {
    cursor: 'pointer',
  },
})
