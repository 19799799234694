import { AuthError } from 'lib/errors'

export const getAccessToken = () => {
  const accessToken = localStorage.getItem('access_token')

  if (!accessToken) {
    throw new AuthError('No access token found')
  }

  return accessToken
}
