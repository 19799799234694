import React, { useEffect } from 'react'

import LicenseKeyContainer from 'containers/licenseKeys/LicenseKeyContainer'
import LicenseKeyPools from 'components/pages/licenseKeys/LicenseKeyPools'

export default ({ history }) => {
  let container = LicenseKeyContainer.useContainer()
  let { fetchLicenseKeyPools } = container

  useEffect(() => {
    fetchLicenseKeyPools()
  }, [fetchLicenseKeyPools])

  let editPool = id => {
    history.push(`pools/${id}`)
  }

  let importKeys = id => {
    history.push(`pools/${id}/import`)
  }

  let pageProps = {
    ...container,
    editPool,
    importKeys,
  }

  return <LicenseKeyPools {...pageProps} />
}
