import React from 'react'
import { Switch, Route } from 'react-router'
import LicenseKeyPoolsRoute from './LicenseKeyPoolsRoute'
import EditLicenseKeyPoolRoute from './EditLicenseKeyPoolRoute'
import ImportLicenseKeysRoute from './ImportLicenseKeysRoute'

export default ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.url}/:id/import`}
        component={ImportLicenseKeysRoute}
      />
      <Route path={`${match.url}/:id`} component={EditLicenseKeyPoolRoute} />
      <Route path={`${match.url}`} component={LicenseKeyPoolsRoute} />
    </Switch>
  )
}
