import React, { useEffect } from 'react'
import AuthContainer from '../../containers/AuthContainer.js'

export default WrappedComponent => {
  let AuthenticatedWrapper = props => {
    let container = AuthContainer.useContainer()
    let isAuthenticated = container.isAuthenticated()
    let { pushRedirectUrl, checkSession } = container
    let { history, location } = props
    let { href } = location

    useEffect(() => {
      ;(async () => {
        // User is not authenticated
        if (!isAuthenticated) {
          // A session could not be established
          let hasSession = await checkSession()
          if (!hasSession) {
            // Save the current location
            pushRedirectUrl(href)
            // Redirect to login
            history.push('/login')
          }
        }
      })()
    }, [isAuthenticated, history, href, checkSession, pushRedirectUrl])

    return <WrappedComponent {...props} />
  }

  return AuthenticatedWrapper
}
