import React, { useEffect } from 'react'
import swal from '@sweetalert/with-react'

import { EntitlementContainer } from 'containers/Entitlements'
import EditEntitlement from 'components/pages/entitlements/EditEntitlement'

const EditEntitlementRoute = props => {
  let container = EntitlementContainer.useContainer()

  let { id } = props.match.params
  let { getEntitlement } = container

  useEffect(() => {
    getEntitlement(id)
  }, [getEntitlement, id])

  let sendEntitlementEmail = id => {
    container.sendEntitlementEmail(id, {
      onSuccess: () => {
        swal('Email Sent', 'The email was sent successfully', 'success')
      },
      onError: err => {
        swal('Error Sending Email', `Error occurred: ${err.message}`, 'error')
      },
    })
  }

  let updateSeats = (id, seats) => {
    container.updateSeats(id, seats, {
      onSuccess: () => {
        swal('Seats Updated', 'The seats have been updated', 'success')
      },
      onError: err => {
        swal('Error Updating Seats', `Error occurred: ${err.message}`, 'error')
      },
    })
  }

  let updateEnds = (id, ends) => {
    container.updateEnds(id, ends, {
      onSuccess: () => {
        swal('End Date Updated', 'The end date has been updated', 'success')
      },
      onError: err => {
        swal(
          'Error Updating End Date',
          `Error occurred: ${err.message}`,
          'error'
        )
      },
    })
  }

  let pageProps = {
    ...props,
    ...container,
    sendEntitlementEmail,
    updateSeats,
    updateEnds,
  }

  return <EditEntitlement {...pageProps} />
}

export default EditEntitlementRoute
