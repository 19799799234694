import React from 'react'

export default (Container, Component) => {
  let ContainerWrapper = props => {
    return (
      <Container>
        <Component {...props} />
      </Container>
    )
  }

  return ContainerWrapper
}
