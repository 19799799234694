import React, { useEffect } from 'react'
import swal from '@sweetalert/with-react'

import LicenseKeyContainer from 'containers/licenseKeys/LicenseKeyContainer'

import ImportLicenseKeys from 'components/pages/licenseKeys/ImportLicenseKeys'
import { BadRequestError } from 'lib/errors'

export default props => {
  let container = LicenseKeyContainer.useContainer()
  let { history, match } = props
  let { id } = match.params
  let { createBatch, fetchLicenseKeyPool, fetchLicenseKeyBatches } = container

  useEffect(() => {
    fetchLicenseKeyPool(id)
    fetchLicenseKeyBatches(id)
  }, [id, fetchLicenseKeyPool, fetchLicenseKeyBatches])

  function goToPools(event) {
    history.push('/admin/licenseKeys/pools')
  }

  const onCreateBatch = (values, { setSubmitting, setErrors, resetForm }) => {
    let { keys } = values
    values = { ...values, id, keys: keys.split('\n') }

    createBatch(values, {
      onSuccess: data => {
        resetForm()
        fetchLicenseKeyBatches(id)
      },
      onError: err => {
        if (err instanceof BadRequestError) {
          setErrors(err.errors)
        } else {
          swal('Unexpected Error', `Error occurred: ${err.message}`, 'error')
        }
        setSubmitting(false)
      },
    })
  }

  let pageProps = {
    ...props,
    ...container,
    onCreateBatch,
    goToPools,
  }

  return <ImportLicenseKeys {...pageProps} />
}
