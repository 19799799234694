import React from 'react'
import { parseISO, format } from 'date-fns'

import Icon from '@material-ui/core/Icon'

import Card from 'components/molecules/Card'
import CardHeader from 'components/molecules/CardHeader'
import CardIcon from 'components/molecules/CardIcon'
import CardBody from 'components/molecules/CardBody'
import Table from 'components/molecules/Table'

export default ({ classes, fetchLicenseKeyBatchesRequest }) => {
  let {
    loading,
    succeeded,
    failed,
    error,
    data: batches,
  } = fetchLicenseKeyBatchesRequest

  if (succeeded) {
    batches = batches.map(b => [
      format(parseISO(b.created), 'MMM do yyyy'),
      b.createdBy,
      b.quantity,
      b.notes || '(none)',
    ])
  }

  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <Icon>waves</Icon>
        </CardIcon>
        <h4 className={classes.cardIconTitle}>License Key Batches</h4>
      </CardHeader>
      <CardBody>
        {loading && 'Loading...'}
        {failed && <div>Error: {error.message}</div>}
        {succeeded && (
          <Table
            striped
            tableHead={['Created', 'Created By', 'Qty', 'Notes']}
            tableData={batches}
            customCellClasses={[classes.left, classes.center, classes.left]}
            customClassesForCells={[0, 2, 3]}
            customHeadCellClasses={[classes.left, classes.center, classes.left]}
            customHeadClassesForCells={[0, 2, 3]}
          />
        )}
      </CardBody>
    </Card>
  )
}
