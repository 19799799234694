import * as yup from 'yup'

export const createEntitlementSchema = values => {
  return yup.object().shape({
    productId: yup.number().required('Product is required'),
    quantity: yup
      .number()
      .required()
      .positive()
      .integer('Please enter a valid quantity'),
    customerName: yup.string().required('Customer name is required'),
    customerEmail: yup
      .string()
      .email('Must be a valid email')
      .required('Customer name is required'),
  })
}

export const createLicenseKeyBatch = values => {
  return yup.object().shape({
    notes: yup.string(),
    keys: yup.string().required('License keys are required'),
  })
}

export const saveLicenseKeyPool = values => {
  return yup.object().shape({
    name: yup.string().required('Name is required'),
    reorderThreshold: yup
      .number('Must be numeric')
      .integer('Must be an integer')
      .required('Required')
      .positive('Must be positive'),
    reorderQuantity: yup
      .number()
      .required()
      .positive()
      .integer('Please enter a valid quantity'),
  })
}
