function ApiError(message, response) {
  var tmp = Error.apply(this, arguments)
  tmp.name = this.name = 'ApiError'
  this.message = tmp.message
  tmp.response = response

  // instead of this.stack = ..., a getter for more optimizy goodness
  Object.defineProperty(this, 'response', {
    get: function() {
      return tmp.response
    },
  })

  return this
}

function AuthError() {
  var tmp = Error.apply(this, arguments)
  tmp.name = this.name = 'AuthError'
  this.message = 'Unauthorized'

  return this
}

const lowerCaseFirstLetter = input => {
  return input.charAt(0).toLowerCase() + input.slice(1)
}

const getErrors = errors => {
  const flatErrors = errors.map(i => ({
    [lowerCaseFirstLetter(i.field)]: i.message,
  }))
  return Object.assign.apply(null, [{}].concat(flatErrors))
}

function BadRequestError(message, response, errors) {
  var tmp = Error.apply(this, arguments)
  tmp.name = this.name = 'RequestError'
  this.message = tmp.message
  tmp.response = response
  tmp.errors = getErrors(errors)

  // instead of this.stack = ..., a getter for more optimizy goodness
  Object.defineProperty(this, 'response', {
    get: function() {
      return tmp.response
    },
  })

  // instead of this.stack = ..., a getter for more optimizy goodness
  Object.defineProperty(this, 'errors', {
    get: function() {
      return tmp.errors
    },
  })

  return this
}

export { AuthError, ApiError, BadRequestError }
