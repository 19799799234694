import { useState, useCallback, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import config from 'config'
import useApi from 'lib/useApi'

let useEntitlement = (initialState) => {
  let [id, setId] = useState(null)
  let [entitlementRequest, entitlementApi] = useApi()
  let [sendEntitlementEmailRequest, sendEmailApi] = useApi()
  let [resetKeyRequest, resetKeyApi] = useApi()
  let [updateSeatsRequest, updateSeatsApi] = useApi()
  let [updateEndsRequest, updateEndsApi] = useApi()

  let sendEntitlementEmail = useCallback(
    (id, options) => {
      sendEmailApi.post(`/ent/entitlement/${id}/activationEmail`, {}, options)
    },
    [sendEmailApi]
  )

  let resetKey = useCallback(
    (key, options) => {
      var values = { licenseKey: key, action: 'reset' }
      resetKeyApi.patch(`${config.homeServiceApiUrl}/license`, values, options)
    },
    [resetKeyApi]
  )

  let getEntitlement = useCallback((id) => {
    setId(id)
  }, [])

  useEffect(() => {
    if (!id) return
    entitlementApi.get(`/ent/entitlement/${id}`)
  }, [entitlementApi, id])

  let createEntitlement = useCallback(
    (values, options) => {
      entitlementApi.post('/ent/entitlements', values, options)
    },
    [entitlementApi]
  )

  let updateSeats = useCallback(
    (id, seats, options) => {
      var values = { seats }
      updateSeatsApi.patch(`/ent/entitlement/${id}/seats`, values, options)
    },
    [updateSeatsApi]
  )

  let updateEnds = useCallback(
    (id, ends, options) => {
      var values = { ends }
      updateEndsApi.patch(`/ent/entitlement/${id}/ends`, values, options)
    },
    [updateEndsApi]
  )

  return {
    getEntitlement,
    createEntitlement,
    entitlementRequest,

    sendEntitlementEmail,
    sendEntitlementEmailRequest,

    resetKey,
    resetKeyRequest,

    updateSeats,
    updateSeatsRequest,

    updateEnds,
    updateEndsRequest,
  }
}

export default createContainer(useEntitlement)
