import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'

import Icon from '@material-ui/core/Icon'

import ReactTable from 'react-table'

import GridContainer from 'components/atoms/GridContainer'
import GridItem from 'components/atoms/GridItem'

// import { makeStyles } from '@material-ui/core/styles'
// const styles = theme => ({
//   root: {
//     flexGrow: 1,
//   },
// })

const LicenseKeyPools = props => {
  let { fetchLicenseKeyPoolsRequest, editPool, importKeys } = props
  let { loading, succeeded, error, data: pools } = fetchLicenseKeyPoolsRequest

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Reorder Threshold',
      accessor: 'reorderThreshold',
    },
    {
      Header: 'Reorder Quantity',
      accessor: 'reorderQuantity',
    },
    {
      Header: 'Available Keys',
      accessor: 'availableKeyCount',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      sortable: false,
      filterable: false,
    },
  ]

  const withActions = () => {
    return !succeeded
      ? []
      : pools.map(p => ({
          ...p,
          actions: (
            <div className="actions-right">
              <Tooltip title="Import Keys" placement="top">
                <Button
                  onClick={() => importKeys(p.id)}
                  color="default"
                  className="remove"
                  aria-label="Import Keys"
                >
                  <Icon>cloud_upload</Icon>
                </Button>
              </Tooltip>
              <Tooltip title="Edit" placement="top">
                <Button
                  onClick={() => editPool(p.id)}
                  color="default"
                  className="remove"
                  aria-label="Import Keys"
                >
                  <Icon>edit</Icon>
                </Button>
              </Tooltip>
            </div>
          ),
        }))
  }

  return (
    <div>
      <GridContainer spacing={24}>
        <GridItem xs={12}>
          <div>
            {loading && <h3>&nbsp;</h3>}
            {error && <h3>Error: {error.message}</h3>}
            <ReactTable
              data={withActions()}
              columns={columns}
              showPaginationTop={false}
              showPaginationBottom={true}
              defaultPageSize={15}
              loading={fetchLicenseKeyPoolsRequest.loading}
            />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default LicenseKeyPools
