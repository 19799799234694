import React from 'react'

import GridContainer from 'components/atoms/GridContainer'
import GridItem from 'components/atoms/GridItem'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'

import LicenseKeyPoolForm from 'components/organisms/licenseKeyPools/LicenseKeyPoolForm'

import styles from './licenseKeysStyle'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(styles)

const EditLicenseKeyPool = props => {
  const { fetchLicenseKeyPoolRequest, goToPools } = props
  const classes = useStyles()

  let {
    loading,
    succeeded,
    failed,
    error,
    data: licenseKeyPool,
  } = fetchLicenseKeyPoolRequest

  let pageProps = { ...props, licenseKeyPool, classes }

  return (
    <React.Fragment>
      {loading && 'Loading...'}
      {failed && <div>Error: {error.message}</div>}
      {succeeded && (
        <GridContainer>
          <GridItem sm={12}>
            <Paper elevation={0} className={classes.paper}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  className={classes.breadcrumbLink}
                  color="inherit"
                  onClick={goToPools}
                >
                  License Key Pools
                </Link>
                <Typography color="textPrimary">
                  {licenseKeyPool.name}
                </Typography>
              </Breadcrumbs>
            </Paper>
          </GridItem>
          <GridItem sm={6}>
            <LicenseKeyPoolForm {...pageProps} />
          </GridItem>
        </GridContainer>
      )}
    </React.Fragment>
  )
}

export default EditLicenseKeyPool
