import React from 'react'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'

import GridContainer from 'components/atoms/GridContainer'
import GridItem from 'components/atoms/GridItem'
import Card from 'components/molecules/Card'
import CardHeader from 'components/molecules/CardHeader'
import CardFooter from 'components/molecules/CardFooter'
import CardIcon from 'components/molecules/CardIcon'
import CardBody from 'components/molecules/CardBody'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import { validate } from 'lib/formHelpers'
import { createLicenseKeyBatch } from 'lib/validationSchema'

let initialValues = {
  notes: '',
  keys: '',
}

export default ({ onCreateBatch, classes }) => {
  return (
    <Formik
      initialValues={initialValues}
      validate={validate(createLicenseKeyBatch)}
      validateOnChange={false}
      onSubmit={onCreateBatch}
      render={({ isSubmitting }) => (
        <Form role="form">
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Icon>cloud_upload</Icon>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Import License Keys</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Field
                    label="Keys"
                    name="keys"
                    margin="normal"
                    component={TextField}
                    fullWidth
                    multiline
                    rows={10}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    label="Notes (Optional)"
                    name="notes"
                    margin="normal"
                    component={TextField}
                    fullWidth
                    multiline
                    rows={3}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="submit" disabled={isSubmitting}>
                Import
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    />
  )
}
