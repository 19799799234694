import React, { useEffect } from 'react'
import AuthContainer from '../../containers/AuthContainer'

export default props => {
  let container = AuthContainer.useContainer()
  let { handleAuthentication, popRedirectUrl } = container
  let { history, location } = props

  useEffect(() => {
    let callback = async () => {
      if (/access_token|id_token|error/.test(location.hash)) {
        try {
          await handleAuthentication(location)
          let redirectUrl = popRedirectUrl() || '/'
          history.push(redirectUrl)
        } catch (err) {
          console.log(err)
          history.push('/login')
        }
      } else {
        history.push('/login')
      }
    }
    if (location) callback()
  }, [history, location, handleAuthentication, popRedirectUrl])

  return <div>Loading...</div>
}
