import { useCallback } from 'react'
import { createContainer } from 'unstated-next'
import useApi from 'lib/useApi'

let useLicenseKeyPools = initialState => {
  let [fetchLicenseKeyPoolsRequest, poolsApi] = useApi()
  let fetchLicenseKeyPools = useCallback(() => {
    poolsApi.get(`/lk/pools`)
  }, [poolsApi])

  let [fetchLicenseKeyPoolRequest, poolApi] = useApi()
  let fetchLicenseKeyPool = useCallback(
    id => {
      poolApi.get(`/lk/pool/${id}`)
    },
    [poolApi]
  )

  let [fetchLicenseKeyBatchesRequest, batchesApi] = useApi()
  let fetchLicenseKeyBatches = useCallback(
    id => {
      batchesApi.get(`/lk/pool/${id}/batches`)
    },
    [batchesApi]
  )

  let [createLicenseKeyPoolRequest, createPoolApi] = useApi()
  let createLicenseKeyPool = useCallback(
    (values, options) => {
      createPoolApi.post(`/lk/pools`, values, options)
    },
    [createPoolApi]
  )

  let [updateLicenseKeyPoolRequest, updatePoolApi] = useApi()
  let updateLicenseKeyPool = useCallback(
    (values, options) => {
      let { id } = values
      updatePoolApi.patch(`/lk/pool/${id}`, values, options)
    },
    [updatePoolApi]
  )

  let [createBatchRequest, createBatchApi] = useApi()
  let createBatch = useCallback(
    (values, options) => {
      let { id } = values
      createBatchApi.post(`/lk/pool/${id}/batches`, values, options)
    },
    [createBatchApi]
  )

  return {
    fetchLicenseKeyPools,
    fetchLicenseKeyPoolsRequest,

    fetchLicenseKeyPool,
    fetchLicenseKeyPoolRequest,

    fetchLicenseKeyBatches,
    fetchLicenseKeyBatchesRequest,

    createLicenseKeyPool,
    createLicenseKeyPoolRequest,

    updateLicenseKeyPool,
    updateLicenseKeyPoolRequest,

    createBatch,
    createBatchRequest,
  }
}

export default createContainer(useLicenseKeyPools)
