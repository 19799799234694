import React, { useState, useEffect } from 'react'
import { parseISO, format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import GridContainer from 'components/atoms/GridContainer'
import GridItem from 'components/atoms/GridItem'
import Card from 'components/molecules/Card'
import CardHeader from 'components/molecules/CardHeader'
import CardIcon from 'components/molecules/CardIcon'
import CardBody from 'components/molecules/CardBody'

import Button from '@material-ui/core/Button'

import KeyIcon from '@material-ui/icons/VpnKey'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import PersonIcon from '@material-ui/icons/Person'
import InfoIcon from '@material-ui/icons/Info'
import CheckIcon from '@material-ui/icons/Check'
import BlockIcon from '@material-ui/icons/Block'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './entitlementsStyle'

import Input from 'components/atoms/CustomInput'

const EditEntitlement = props => {
  const [endDate, setEndDate] = useState(null)
  const [seats, setSeats] = useState(0)
  const {
    classes,
    entitlementRequest,
    sendEntitlementEmail,
    sendEntitlementEmailRequest,
    updateSeats,
    updateSeatsRequest,
    updateEnds,
    updateEndsRequest,
  } = props

  let {
    loading,
    failed,
    error,
    succeeded,
    data: entitlement,
  } = entitlementRequest

  useEffect(() => {
    if (!entitlement) return

    if (!entitlement.isPerpetual) {
      setEndDate(parseISO(entitlement.ends))
    }

    setSeats(entitlement.seats)
  }, [entitlement])

  const getDate = date => {
    if (!date) return 'Never'

    if (Object.prototype.toString.call(date) === '[object Date]') {
      // it is a date
      if (isNaN(date.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        return 'Invalid Date'
      } else {
        // date is valid
        return format(date, 'MMM dd yyyy')
      }
    } else {
      date = parseISO(date)
      return format(date, 'MMM dd yyyy')
    }
  }

  const getCheck = val => (val ? <CheckIcon /> : <BlockIcon />)

  return (
    <React.Fragment>
      {loading && 'Loading...'}
      {failed && <div>Error: {error.message}</div>}
      {succeeded && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <GridContainer>
            <GridItem sm={6}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <KeyIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Entitlement Info</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem sm={6}>
                      <Input
                        labelText="Activation Code"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: entitlement.activationCode,
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem sm={6}>
                      <Input
                        labelText="Product"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: entitlement.product.name,
                        }}
                      />
                    </GridItem>
                    <GridItem sm={6}>
                      <Input
                        labelText="Starts"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: getDate(entitlement.starts),
                        }}
                      />
                    </GridItem>
                    <GridItem sm={6}>
                      <Input
                        labelText="Created By"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: entitlement.createdBy,
                        }}
                      />
                    </GridItem>
                    <GridItem sm={6}>
                      {entitlement.isPerpetual && (
                        <Input
                          labelText="Ends"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: 'Never',
                          }}
                        />
                      )}
                      {entitlement.isPerpetual || (
                        <KeyboardDatePicker
                          disableToolbar
                          fullWidth
                          variant="inline"
                          format="MMM dd yyyy"
                          margin="normal"
                          label="Ends"
                          value={getDate(endDate)}
                          onChange={date => setEndDate(date)}
                        />
                      )}
                      <Button
                        className={classes.updateButton}
                        disabled={updateEndsRequest.loading}
                        onClick={() => updateEnds(entitlement.id, endDate)}
                      >
                        Update End Date
                      </Button>
                    </GridItem>
                    <GridItem sm={6}>
                      <Input
                        labelText="Seats"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: seats,
                          disabled: !entitlement.product.isSeatBased,
                          onChange: e => setSeats(e.target.value),
                        }}
                      />
                      <Button
                        className={classes.updateButton}
                        disabled={
                          !entitlement.product.isSeatBased ||
                          updateSeatsRequest.loading
                        }
                        onClick={() => updateSeats(entitlement.id, seats)}
                      >
                        Update Seats
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem sm={6}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <InfoIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Status</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem sm={12}>
                      <GridItem sm={8}>
                        <div>Active</div>
                      </GridItem>
                      <GridItem sm={4}>
                        <div>{getCheck(entitlement.isActive)}</div>
                      </GridItem>
                    </GridItem>
                    <GridItem sm={12}>
                      <GridItem sm={10}>
                        <div>Valid</div>
                      </GridItem>
                      <GridItem sm={2}>
                        {getCheck(entitlement.isValid)}
                      </GridItem>
                    </GridItem>
                    <GridItem sm={12}>
                      <GridItem sm={10}>
                        <div>Started</div>
                      </GridItem>
                      <GridItem sm={2}>
                        {getCheck(entitlement.isStarted)}
                      </GridItem>
                    </GridItem>
                    <GridItem sm={12}>
                      <GridItem sm={10}>
                        <div>Expired</div>
                      </GridItem>
                      <GridItem sm={2}>
                        {getCheck(entitlement.isExpired)}
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem sm={6}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <PersonIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Customer Info</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem sm={6}>
                      <Input
                        labelText="Name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: entitlement.customerName,
                        }}
                      />
                    </GridItem>
                    <GridItem sm={6}>
                      <Input
                        labelText="Email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: entitlement.customerEmail,
                        }}
                      />
                      <Button
                        className={classes.updateButton}
                        onClick={() => sendEntitlementEmail(entitlement.id)}
                        disabled={sendEntitlementEmailRequest.loading}
                      >
                        Resend License Key
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem sm={6}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <MoneyIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Order Info</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem sm={6}>
                      <Input
                        labelText="Source"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: entitlement.source,
                        }}
                      />
                    </GridItem>
                    <GridItem sm={6}>
                      <Input
                        labelText="Source Sku"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: entitlement.sourceSku,
                        }}
                      />
                    </GridItem>
                    <GridItem sm={6}>
                      <Input
                        labelText="Subscription Reference"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: entitlement.subscriptionReference,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </MuiPickersUtilsProvider>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(EditEntitlement)
