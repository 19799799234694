import { useCallback } from 'react'
import { createContainer } from 'unstated-next'
import useApi from 'lib/useApi'

let useEntitlementProducts = initialState => {
  let [fetchProductsRequest, api] = useApi()

  let fetchProducts = useCallback(
    includeInactive => {
      api.get('/ent/products')
    },
    [api]
  )

  return {
    fetchProducts,
    fetchProductsRequest,
  }
}

export default createContainer(useEntitlementProducts)
