let { useRef, useEffect } = require('react')

export default (title: string, retainOnUnmount = false) => {
  const defaultTitle = useRef(document.title)

  useEffect(() => {
    document.title = title
    const currentTitle = defaultTitle.cuurent

    return () => {
      if (!retainOnUnmount) document.title = currentTitle
    }
  }, [title, retainOnUnmount])
}
