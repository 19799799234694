import React from 'react'
import { useContainer } from 'unstated-next'

// @material-ui/core components
import Hidden from '@material-ui/core/Hidden'
import Tooltip from '@material-ui/core/Tooltip'

// @material-ui/icons
import LogoutIcon from '@material-ui/icons/ExitToApp'

// core components
import Button from '../atoms/Button.jsx'

import AuthContainer from 'containers/AuthContainer'

import { makeStyles } from '@material-ui/core/styles'
import styles from './adminNavbarLinksStyle.jsx'
const useStyles = makeStyles(styles)

const HeaderLinks = props => {
  const classes = useStyles()
  const container = useContainer(AuthContainer)

  const handleLogout = () => {
    container.logout()
  }

  return (
    <div>
      <Tooltip title="Logout">
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          className={classes.buttonLink}
          muiClasses={{
            label: '',
          }}
          onClick={handleLogout}
        >
          <LogoutIcon
            className={classes.headerLinksSvg + ' ' + classes.links}
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>{'Profile'}</span>
          </Hidden>
        </Button>
      </Tooltip>
    </div>
  )
}

export default HeaderLinks
