import React, { useEffect } from 'react'
import swal from '@sweetalert/with-react'

import LicenseKeyContainer from 'containers/licenseKeys/LicenseKeyContainer'

import EditLicenseKeyPool from 'components/pages/licenseKeys/EditLicenseKeyPool'
import { BadRequestError } from 'lib/errors'

const EditLicenseKeyPoolRoute = props => {
  let container = LicenseKeyContainer.useContainer()
  let { history, match } = props
  let { id } = match.params
  let { updateLicenseKeyPool, fetchLicenseKeyPool } = container

  useEffect(() => {
    fetchLicenseKeyPool(id)
  }, [id, fetchLicenseKeyPool])

  function goToPools(event) {
    history.push('/admin/licenseKeys/pools')
  }

  const onUpdateLicenseKeyPool = (values, { setSubmitting, setErrors }) => {
    values = { ...values, id }

    updateLicenseKeyPool(values, {
      onSuccess: data => {
        history.push('/admin/licenseKeys/pools')
      },
      onError: err => {
        if (err instanceof BadRequestError) {
          setErrors(err.errors)
        } else {
          swal('Unexpected Error', `Error occurred: ${err.message}`, 'error')
        }
        setSubmitting(false)
      },
    })
  }

  let pageProps = {
    ...props,
    ...container,
    onUpdateLicenseKeyPool,
    goToPools,
  }

  return <EditLicenseKeyPool {...pageProps} />
}

export default EditLicenseKeyPoolRoute
